export const getErrorLogMessage = (error: Error) => {
  const getInnerError = (outer: Error) => {
    if (outer.cause instanceof Error) {
      return getInnerError(outer.cause)
    }
    return outer
  }

  const inner = getInnerError(error)

  return inner === error ? error.message : `${error.message} (${inner.message})`
}
