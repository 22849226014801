import type {
  AuthenticatedSession,
  UnauthenticatedSession,
} from '@mntn-dev/auth-types'
import { AuthenticationError } from '@mntn-dev/errors'

import { sessionHasPrincipal } from './session-has-principal.ts'
import { sessionIsExpired } from './session-is-expired.ts'

export const authenticateSession = (
  session: UnauthenticatedSession
): AuthenticatedSession => {
  if (!session) {
    throw new AuthenticationError('No session found', {
      code: 'missing_session',
    })
  }

  if (sessionIsExpired(session)) {
    throw new AuthenticationError('Session expired', {
      code: 'expired_session',
    })
  }

  if (!sessionHasPrincipal(session)) {
    throw new AuthenticationError('No session principal found', {
      code: 'missing_principal',
    })
  }

  return session
}
