import { Modal, type ModalProps } from '@mntn-dev/ui-components'

type NotificationModalProps = ModalProps

const NotificationModal = ({ children, ...props }: NotificationModalProps) => (
  <Modal
    {...props}
    backgroundBlur="blur-sm"
    align="start"
    justify="start"
    width="auto"
    className="left-16"
  >
    {children}
  </Modal>
)

export { NotificationModal, type NotificationModalProps }
